import Vue from 'vue'
import App from './App.vue'
import global from '@/utlis/global'
import UAParser from 'ua-parser-js'

import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
const firebaseConfig = {
  apiKey: 'AIzaSyDE1Ld8SupKgym6jvGH3sPvHTih0OL072k',
  authDomain: 'gbwmod-com.firebaseapp.com',
  projectId: 'gbwmod-com',
  storageBucket: 'gbwmod-com.appspot.com',
  messagingSenderId: '70268325542',
  appId: '1:70268325542:web:c2d6a21dd5dcd970f3ae67',
  measurementId: 'G-0HT9NZ46ZZ',
}
// Initialize Firebase
let app = null
let analytics = null

const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'

isSupported().then((result) => {
  if (result) {
    app = initializeApp(firebaseConfig)
    analytics = getAnalytics(app)

    logEvent(analytics, 'in_page_syntrius')
  }
})

// simple event

Vue.prototype.$global = global
Vue.prototype.$server = global.server
Vue.prototype.$ua = {
  deviceInfo,
  isMobileDevice,
}
Vue.prototype.$logEvent = (event, params = {}) => {
  isSupported().then((result) => {
    if (result) {
      logEvent(analytics, event, params)
    }
  })
}

new Vue({
  data() {
    return {
      month: '',
      isMobileDevice: isMobileDevice,
    }
  },
  created() {
    // window["__INITIAL_STATE__"] = 'Desktop'
  },
  methods: {
    _isMobile() {
      if (typeof navigator !== 'undefined') {
        // 在浏览器环境中执行的代码
        // 使用 navigator 对象进行相关操作
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      } else {
        // 在服务器环境中执行的代码
        // 忽略或提供替代方案
        return false
      }
    },
  },
  render: (h) => h(App),
}).$mount('#app')
