<template>
  <component :is="contentComponent" />
</template>

<script>
import "@/css/main.scss";
import "aos/dist/aos.css"; // You can also use <link> for styles

import HomeMobileContent from "@/pages/mobile/index.vue";
import HomePCContent from "@/pages/pc/index.vue";

export default {
  data () {
    return {
      contentComponent: null,
    };
  },
  mounted () {
    this.$logEvent("show_home");
    this.checkDeviceType();

    if (typeof document !== 'undefined') {
      // Only initialize AOS in the client-side
      const AOS = require("aos");
      AOS.init();
    }
  },
  methods: {
    checkDeviceType () {
      const isMobile = window.innerWidth < 768; // 假设屏幕宽度小于768px为移动设备
      this.contentComponent = isMobile ? HomeMobileContent : HomePCContent;
    }
  }
};
</script>
