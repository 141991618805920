<template>
  <div class="home mobile">
    <!-- 第一个模块：全屏背景图 -->
    <div class="header">
      <div class="logo-row">
        <img src="@/assets/syntrius/logo.png" alt="logo">
      </div>

      <div class="hamburger" id="bars" ref="hamburger" @click="dropFunc()">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </div>
    <!-- 下拉菜单 -->
    <transition name="dropdown-fade-show">
      <div ref="dropdown" v-show="dropDownShow" @touchmove.prevent class="dropdown" id="dropdown">
        <div class="drop-content">
          <div class="product-list">
            <div class="product-item">
              <a @click="scrollToTop">Home</a>
            </div>
          </div>
          <div class="product-list">
            <div class="product-item">
              <a href="/privacy.html">Privacy Policy</a>
            </div>
          </div>
          <div class="product-list">
            <div class="product-item">
              <a href="mailto:ad_contact@syntrius.com">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="fullscreen-background">
      <!-- 可以放置一些内容或标题 -->
      <h1 class="module-1-title" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
        Empower Your Growth! <br>
        Connect, Advertise, Succeed!
      </h1>
      <h2 class="module-1-content" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
        Empower advertisers with premium traffic, cutting-edge algorithms, and exceptional <br>
        ad materials for global growth.
      </h2>

    </div>

    <div class="normal-module module-2">
      <div class="company-adver-row">
        <div class="company-adver-item">
          <div class="company-adver-graphy" data-aos="fade-right" data-aos-duration="1000">
            <picture>
              <source srcset="@/assets/syntrius/adv-1.png" type="image/png">
              <!-- 如果不支持 WebP，将回退到 PNG -->
              <img src="@/assets/syntrius/adv-1.png" alt="graphy-1"> <!-- 如果上述两个源都不可用，将显示此图片 -->
            </picture>
          </div>
          <div class="company-adver-item-info-row" data-aos="fade-left" data-aos-duration="1000">
            <div class="company-adver-title">
              About Syntrius
            </div>
            <div class="company-adver-content">
              Syntrius, headquartered in HongKong, is a mobile ad tech company committed to
              enhancing business growth through S2S and API integration. Our goal is to simplify the
              process for agencies and brands to reach their target audiences and for publishers to
              monetize their apps effectively. With our in-depth industry expertise, we offer a
              comprehensive suite of tools designed to streamline mobile ad technology. <br>
              Syntrius offers over 10,000 high-quality CPA/CPI/CPS campaigns, covering various
              sectors including e-commerce, gaming, finance, and social media, and more.
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="normal-module module-3 ">
      <div class="list-adv-row">
        <div class="list-adv-item">
          <div class="list-adv-icon">
            <picture>
              <source srcset="@/assets/syntrius/list-adv-1.png" type="image/png">
              <!-- 如果不支持 WebP，将回退到 PNG -->
              <img src="@/assets/syntrius/list-adv-1.png" alt="list-adv-1">
              <!-- 如果上述两个源都不可用，将显示此图片 -->
            </picture>
          </div>
          <div class="list-adv-title">
            For Advertiser
          </div>
          <div class="list-adv-list">
            <ul>
              <li>
                Boost your mobile audience reach effectively
              </li>
              <li>
                Optimize campaign performance
              </li>
              <li>
                Our focus: Your ROI
              </li>
            </ul>
          </div>
        </div>
        <div class="list-adv-item">
          <div class="list-adv-icon">
            <picture>
              <source srcset="@/assets/syntrius/list-adv-2.png" type="image/png">
              <!-- 如果不支持 WebP，将回退到 PNG -->
              <img src="@/assets/syntrius/list-adv-2.png" alt="list-adv-2">
              <!-- 如果上述两个源都不可用，将显示此图片 -->
            </picture>
          </div>
          <div class="list-adv-title">
            For Advertiser
          </div>
          <div class="list-adv-list">
            <ul>
              <li>
                Optimize your mobile earnings
              </li>
              <li>
                Explore high-performing global offers
              </li>
              <li>
                Benefit from timely payouts
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 后续模块 -->
    <div class="normal-module module-4 ">

      <div class="numbers-row">
        <div class="number-item">
          <div class="numbers" data-aos="flip-left">
            2B+
          </div>
          <div class="number-memo">
            Impression
          </div>
        </div>
        <div class="number-item">
          <div class="numbers" data-aos="flip-left">
            10000+
          </div>
          <div class="number-memo">
            Offers
          </div>
        </div>
        <div class="number-item">
          <div class="numbers" data-aos="flip-left">
            35+
          </div>
          <div class="number-memo">
            Verticals
          </div>
        </div>
        <div class="number-item">
          <div class="numbers" data-aos="flip-left">
            200+
          </div>
          <div class="number-memo">
            Countries
          </div>
        </div>
        <div class="number-item">
          <div class="numbers" data-aos="flip-left">
            5000+
          </div>
          <div class="number-memo">
            Publishers
          </div>
        </div>
      </div>
    </div>

    <div class="normal-module module-5">

      <div class="our-team-row">
        <div class="our-team-title">
          <div class="team-title-txt">
            Our Clients
          </div>
        </div>
        <div class="our-team-logo-row">
          <div class="team-logo-item" v-for="i in 8" data-aos="fade-up" data-aos-duration="500">
            <img :src="require(`@/assets/syntrius/c-${i}.png`)" alt="">
          </div>
        </div>
      </div>

    </div>

    <div class="normal-module module-6">

      <div class="our-team-row">
        <div class="our-team-title">
          <div class="team-title-txt">
            Our Partners
          </div>
        </div>
        <div class="our-team-logo-row">
          <div class="team-logo-item" v-for="i in 8" data-aos="fade-up" data-aos-duration="500">
            <img :src="require(`@/assets/syntrius/pa-${i}.png`)" alt="">
          </div>
        </div>
      </div>

    </div>

    <footer class="normal-module module-7">
      <div class="footer-content-row">
        <div class="logo-row cursor">
          <img src="@/assets/syntrius/logo.png" alt="logo">
        </div>

        <div class="best-us">
          Empower Your Growth! Connect, Advertise, Succeed!
        </div>

      </div>
      <div class="dash-line"></div>

      <div class="jump-url">
        <div class="jump-url-item cursor">
          <a @click="scrollToTop">Home</a>
        </div>
        <div class="jump-url-item">
          <a href="/privacy.html">Privacy Policy</a>
        </div>
        <div class="jump-url-item">
          <a href="mailto:ad_contact@syntrius.com">Contact Us</a>
        </div>
      </div>

      <div class="dash-line"></div>
      <div class="copyright">
        © Copyright 2024 Syntrius Co., Limited
      </div>

    </footer>

  </div>
</template>

<script>
import "@/css/pc.scss";
import "@/css/mobile.scss";
import { lock, unlock } from "tua-body-scroll-lock";

export default {
  name: "homeM",
  data () {
    return {
      dropDownShow: false,
    };
  },
  methods: {
    dropFunc () {
      this.dropDownShow = !this.dropDownShow;
      if (this.dropDownShow) {
        lock();
        // 添加全局点击事件监听器
        setTimeout(
          () =>
            document.addEventListener(
              "click",
              this.handleClickOutside
            ),
          0
        );
      }
      if (!this.dropDownShow) {
        unlock();
        // 移除监听器
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside (event) {
      const dropdownElement = this.$refs.dropdown;
      const hamburgerElement = this.$refs.hamburger;
      // 检查点击是否在下拉菜单或汉堡菜单按钮外部
      if (
        dropdownElement &&
        !dropdownElement.contains(event.target) &&
        hamburgerElement &&
        !hamburgerElement.contains(event.target)
      ) {
        this.dropFunc();
      }
    },
    scrollToTop () {
      let topHeight =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let speed = topHeight / 10 > 100 ? topHeight / 10 : 100;
      scrollBy(0, -speed);
      // 模拟鼠标向上滚动事件
      this.scrolldelay = setTimeout(this.scrollToTop, 50);
      // 清除滚动事件，避免无法向下移动
      if (topHeight === 0) {
        clearTimeout(this.scrolldelay);
        this.scrolldelay = null;
      }
    },
  },
};
</script>
